import React, { Component } from 'react';

export class Introduction extends Component {
    render() {
        return (
            <div className="container-sm" style={{marginTop: "30px"}}>
                <div id="Introduction" style={{ marginBottom: "-0.5rem" }}>
                    <h1 style={{ marginBottom: "30px" }}>HKUST FINTECHSTIC 2023</h1>
                    <span style={{lineHeight: "2rem"}}>HKUST Fintechstic proudly presented by HKUST Business School and in celebration of the Hong Kong FinTech Week is coming back to town! This year, Fintechstic continues to offer engaging and educational activities to local university students and the public, with a new theme that centers around achieving green sustainable development goals (SDGs) through financial innovations.</span>
                </div>
            </div>

        )
    }
}

export default Introduction
