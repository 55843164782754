// ********** DRIVING PROGRAM **********

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Navbar from "./Navbar";
import Home from './Home';
import Faq from './Faq';
import About from './About';
import ScrollToTop from './ScrollToTop';

class Main extends Component {

    // componentDidMount() {
    //     $("#Navbar a").on('click', function (event) {
    //         // Make sure this.hash has a value before overriding default behavior
    //         console.log(this.hash);
    //         if (this.hash !== "") {
    //             // Prevent default anchor click behavior
    //             event.preventDefault();
    //             // Store hash
    //             var hash = this.hash.replace('#','');
        
    //             var element = document.getElementById(hash);
    //             var headerOffset = 500;
    //             var elementPosition = element.top;
    //             var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    //             // Using jQuery's animate() method to add smooth page scroll
    //             // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
    //             window.scrollTo({
    //                 top: offsetPosition,
    //                 behavior: "smooth"
    //             });
    //             window.location.hash = hash;
    //         }
    //     });
    // }

    render() {
        return (
            <BrowserRouter>
                <ScrollToTop />
                <Navbar />
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/about' component={About} />
                    <Route exact path='/competition/faq' component={Faq} />
                </Switch>
            </BrowserRouter>

        );
    }
}

ReactDOM.render(<Main />, document.getElementById('root'));
