import React, {Component} from 'react';
import "./Faq.css"

export class Faq extends Component{
    componentDidMount() {
        document.querySelector("body").scrollTo(0,0)
    }
    render() {
        return(
            <div className="container-sm">
                <h1>Frequently Asked Questions</h1>
                <div className="nav-dropdown-faq-q">
                    <p>Q1: What is Fintechstic 2023?</p>
                    <p>
                        Fintechstic 2023, jointly organized by HKUST Business School and The Base and supported by InvestHK in celebration of the Hong Kong Fintech Week 2023, offers you a series of fun, engaging and educational activities to test your knowledge, experience & learn and innovate creative solutions related to the theme of NFTs and the Metaverse.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q2. Who can take part in Fintechstic 2023 Student Competition?</p>
                    <p>
                        We welcome full-time students from any one of the local universities in Hong Kong, regardless of level, year of study and major to participate in the Fintechstic 2023 Student Competition. However, Co-organizer’s employees and related persons, such as their immediate families (i.e. spouses, siblings, children, parents, spouses’ parents and the spouses of any of the above individuals) and their household members are not eligible to join the Competition.
                    </p>
                    <p>
                        All participants must present valid student cards and provide a copy to the Organizer for verification.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q3: When is the application deadline?</p>
                    <p>
                        Please submit your application by 10 October 2023 (Mon) 23:59.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q4: Can I participate as an individual?</p>
                    <p>
                        Participation in the Student Competition must be on a team basis. You may find your team members via the <a href="https://join.slack.com/t/slack-cgc5446/shared_invite/zt-v6gaazgy-SrJ~dfldnIsDTdrX16Ir9A" style={{color:"#4582ec"}}>Fintechstic matching platform</a>.
                    </p>
                </div>

                <div className="nav-dropdown-faq-q">
                    <p>Q5: I am currently not in Hong Kong. Can I still participate?</p>
                    <p>
                        No. All team members are required to physically attend and participate in the mentoring session and the Final Day activities.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q6: How do I know if I have registered successfully?</p>
                    <p>
                        A confirmation email along with the topic of the competition will be sent to the email address as provided in your registration on 14 Oct (Fri). Please contact us at fintech@ust.hk if you do not receive this confirmation email.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q7: How many members are allowed in a team?</p>
                    <p>
                        A team should consist of 2 to 5 members. Your team members can come from different universities and backgrounds. Each team can only have at most 1 postgraduate student.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q8: Can I join more than one team?</p>
                    <p>
                        No. An eligible individual cannot join more than one team.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q9: Can I add/remove/change team members after submitting registration?</p>
                    <p>
                        Once a team has submitted its application, no modification in its composition will be allowed. The first submission of the registration form is considered as the final submission.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q10: How can I submit the final proposal?</p>
                    <p>
                        Further instructions will be provided to participants via email.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q11: What is the language requirement? Do I need to know Cantonese to join Fintechstic 2023 Student Competition?</p>
                    <p>
                        All submissions, presentations, and Q&As must be delivered in English only.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q12: Can I join Fintechstic 2023 Student Competition if I do not have any prior knowledge/ experience about NFTs and the Metaverse?</p>
                    <p>
                        Sure! We will provide training workshops to equip participants with sufficient knowledge about NFTs and the Metaverse for the Competition.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q13: How much does it cost to enroll in Fintechstic 2023?</p>
                    <p>
                        All workshops and competition of Fintechstic 2023 are free to participate.
                    </p>
                </div>
                <div className="nav-dropdown-faq-q">
                    <p>Q14: If I have further questions about Fintechstic 2023, who should I contact?</p>
                    <p>
                        Please email the Organizer at <a href="mailto:fintech@ust.hk" style={{color:"#4582ec"}}>fintech@ust.hk</a>.
                    </p>
                </div>
                <div style={{ width: "100%", height: "100px" }}></div>
            </div>
        )
    }
}

export default Faq
