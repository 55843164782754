import React, {Component} from 'react';
import HKUSTLogo from './assets/img/Footer_HKUSTLogo.svg'
import "./Footer.css"

export class Footer extends Component{
    render() {
        return(
            <div>
                <div className="container-fluid vertical_container res-hide-footer" style={{ width:"100%", padding:"1%", display:"flex", flexDirection:"row", justifyContent:"space-evenly", alignItems: "center"}}>
                    <div id="footer1"><a href="https://hkust.edu.hk/" target="_blank"><HKUSTLogo/></a></div>
                    <div id="footer2">
                        <div style={{ textAlign:"left" }}>
                            <a className="footer_heading" href="https://dataprivacy.ust.hk/university-data-privacy-policy-statement" target="_blank" style={{textDecorationLine:"unset"}}>Privacy Policy</a><br/>
                            <span className="footer">Copyright © The Hong Kong University of Science and Technology. All rights reserved. </span>
                        </div>
                    </div>
                    <div id="footer3">
                        <div id="footer3-content">
                            <span className="footer">Follow HKUST on: </span>
                            <span>
                                <a className="btn btn-border" href="https://www.facebook.com/hkust" target="_blank">
                                    <i className="fab fa-facebook-f fa-lg fa_logo" />
                                </a>
                                <a className="btn btn-border" href="https://www.instagram.com/hkust" target="_blank">
                                    <i className="fab fa-instagram fa-lg fa_logo" />
                                </a>
                                <a className="btn btn-border" href="https://www.linkedin.com/school/hkust" target="_blank">
                                    <i className="fab fa-linkedin-in fa-lg fa_logo" />
                                </a>
                                <a className="btn btn-border" href="https://www.youtube.com/user/HKUST" target="_blank">
                                    <i className="fab fa-youtube fa-lg fa_logo" />
                                </a>
                                <a className="btn btn-border" href="https://hkust.edu.hk/sites/default/files/menu_icons/mainsite_Wechat_Eng_new.jpg" target="_blank">
                                    <i className="fab fa-weixin fa-lg fa_logo" />
                                </a>
                            </span>
                            <div style={{ width:"100%", height:"0.2cm" }}></div>
                            <span className="footer">Contact Fintech and Green Finance Projects: <a href="mailto:fintech@ust.hk" target="_blank" style={{textDecorationLine:"unset",color:"white"}}>fintech@ust.hk</a></span>
                        </div>
                    </div>
                </div>

                <div className="res-hide-footer-2 container-fluid" id="mobile-footer">
                    <div id="mobile-footer1">
                        <a href="https://dataprivacy.ust.hk/university-data-privacy-policy-statement">Privacy Policy</a>
                    </div>
                    <div>
                        Follow HKUST on
                    </div>
                    <div id="mobile-footer3">
                        <a className="btn btn-border" href="https://www.facebook.com/hkust" target="_blank">
                            <i className="fab fa-facebook-f fa-lg fa_logo" />
                        </a>
                        <a className="btn btn-border" href="https://www.instagram.com/hkust" target="_blank">
                            <i className="fab fa-instagram fa-lg fa_logo" />
                        </a>
                        <a className="btn btn-border" href="https://www.linkedin.com/school/hkust" target="_blank">
                            <i className="fab fa-linkedin-in fa-lg fa_logo" />
                        </a>
                        <a className="btn btn-border" href="https://www.youtube.com/user/HKUST" target="_blank">
                            <i className="fab fa-youtube fa-lg fa_logo" />
                        </a>
                        <a className="btn btn-border" href="https://hkust.edu.hk/sites/default/files/menu_icons/mainsite_Wechat_Eng_new.jpg" target="_blank">
                            <i className="fab fa-weixin fa-lg fa_logo" />
                        </a>
                    </div>
                    <a href="https://hkust.edu.hk/" target="_blank"><HKUSTLogo/></a>
                    <div id="mobile-footer4">
                        Copyright &copy; The Hong Kong University of Science and Technology. All rights reserved.
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
