import React, {Component} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import {isMobile} from 'react-device-detect';
import Logo from './assets/img/HKUST_Fintechstic_Logo.svg';
import "./Navbar.css"


class Navbar extends Component {

    constructor(props) {
        super(props);
        this.onClickNavBar = this.onClickNavBar.bind(this);
    }

    componentDidMount() {
        $('.navbar-collapse a').click(function(){
            $(".collapse").collapse('hide');
        });
    }

    onClickNavBar(hash) {
        if (isMobile) {
            location.hash= hash;
            location.reload();
        }
    }

    render() {
        return (
            <div className="sticky-top" id="Navbar">
                <nav className="navbar navbar-expand-lg navbar-light navbar-custom" style={{paddingTop:".2rem",paddingBottom:".2rem", height:"5%"}}>
                    <div className="container-fluid res-header" style={{ flexDirection:"column", alignItems:"flex-end" }}>
                        <ul className="nav" id="header-ul">
                            <li className="nav-item">
                                <a className="nav-link" href="https://hkust.edu.hk" target="_blank"><span className="header">HKUST</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://fintech.hkust.edu.hk/" target="_blank"><span className="header">Fintech and Green Finance Projects</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"><span className="header">Follow us</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" style={{padding: "unset"}} href="https://www.instagram.com/fintechhkust/" target="_blank" ><i className="fab fa-instagram fa-lg fa_logo" /></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" style={{padding: "unset"}} href="https://www.linkedin.com/company/hkust-fintech-research-project/" target="_blank" ><i className="fab fa-linkedin fa-lg fa_logo" /></a>
                            </li>
                            <li className="nav-item" style={{ paddingRight: "25px" }}>
                                <a className="nav-link" href="mailto:fintech@ust.hk" target="_blank"><span className="header">Contact Us</span></a>
                            </li>
                        </ul>
                    </div>
                </nav>

                <nav className="navbar navbar-expand-lg navbar-light bg-white" style={{ border: "none"}}>
                    <div className="container-fluid" style={{justifyContent:"center"}}>
                        <div className="svg-container header_svg" style={{ padding:"0.3cm", margin:"unset" }}><span id="navbar-fintechstic-logo" onClick={ () => window.location.href="/" }><Logo/></span></div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ flexGrow:"unset" }}>
                            <ul id="navbar-ul" className="navbar-nav me-auto mb-2 mb-lg-0 align-items-center" style={{background:"white"}}>
                                <li className="nav-item">
                                    <Link to={'/'} onClick={()=>document.querySelector("body").scrollTo(0,0)}><span className="nav-link active" aria-current="page">HOME</span></Link>
                                </li>
                                <li className="nav-item">
                                    <a href="/#Webinars" onClick={()=>this.onClickNavBar('Webinars')}><span className="nav-link">WEBINARS</span></a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#Competition" onClick={()=>this.onClickNavBar('Competition')}><span className="nav-link">STUDENT<br/>COMPETITION</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://ust.az1.qualtrics.com/jfe/form/SV_8pPgOkOGTpw1wBU" target="_blank">ONLINE TRIVIA</a>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/about'}><span className="nav-link">ABOUT US</span></Link>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                                       data-bs-toggle="dropdown" aria-expanded="false" onClick={CaseComOnClick}>
                                        CASE COMPETITION
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link to={'/competition/format'}><span className="dropdown-item" aria-current="page" style={{textDecorationColor:"transparent"}}> Format & Rules </span></Link></li>
                                        <li><a className="dropdown-item" href="/#CaseCom_KeyDates"> Key Dates </a></li>
                                        <li><a className="dropdown-item" href="/#CaseCom_Awards"> Awards </a></li>
                                        <li><Link to={'/competition/faq#top'}><span className="dropdown-item" aria-current="page" style={{textDecorationColor:"transparent"}}> FAQ </span></Link></li>
                                        <li><a className="dropdown-item" href="https://ust.az1.qualtrics.com/jfe/form/SV_0wXJuf0ZNf5Lmx8" target="_blank"> Apply Now </a></li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );

    }
}

export default Navbar;
