import React, { Component } from 'react';
import webinarBanner from './assets/img/banner_webinar.png';
import "./Webinars.css"

export class Webinars extends Component {
    render() {
        return (
        <>
            <a className="anchor" id="Webinars"></a>
            <div style={{ "minWidth": "100%", "marginTop": "30px", "marginBottom": "30px" }}>
                    <img src={webinarBanner} />
            </div>
			<div className="container-sm">
                    <span style={{lineHeight: "2rem"}}>Experts from the industry will be offering valuable knowledge and insights to help students navigate the crossroads between green finance and digital assets – two of the hottest topics in the finance industry in Hong Kong. The webinars are open to the public.</span><br /><br />
            </div>
            <div className="container-sm">
                <div className="row">
                    <div className="col-sm-6">
                        <p><b>Exploring the World of Security Tokens and Non-Security Tokens in the Modern Financial Landscape</b></p>
                        <p className="webinar-content">
                            Date: 27 Sep 2023(Wed)<br />
                            Time: 6:00 - 7:30pm<br />
							Format: Online via Zoom<br />
							Medium: English<br />
                            Speakers:<br />
							<ul>
								<li>Ms. Anna Liu, General Manager of Tokenisation at HashKey Group</li>
								<li>Mr. Samson Lee, Founder and CEO of Coinstreet, and Signum Digital</li>
							</ul>
							<br />
							<a href="https://www.instagram.com/p/Cxuea5fpFIa/" target="blank"><h3>Webinar Highlights &gt;&gt;&gt; </h3></a>
                        </p>
                    </div>
                    <div className="col-sm-6">
                        <p><b>Sailing Towards a Greener Future: Navigating the World of Green Finance</b></p>
                        <p className="webinar-content">
                            Date: 11 Oct 2023 (Wed)<br />
                            Time: 6:00 - 7:30pm<br />
							Format: Online via Zoom<br />
							Medium: English<br />
                            Speakers:<br />
							<ul>
								<li>Mr. Jakub Malich, Vice President, ESG & Climate Research, MSCI</li>
								<li>Mr. Mehul Thakkar, Executive Director, Head of ESG Issuer Relations, Asia Pacific, MSCI</li>
							</ul>
							<br />
							<a href="https://www.instagram.com/p/CyhnvNqhJxq/" target="blank"><h3>Webinar Highlights &gt;&gt;&gt; </h3></a>
                        </p>
                    </div>
                </div>
				
				{/*<div className="row" style={{marginTop: "30px"}}><a href="https://fintech.hkust.edu.hk/home#news-events" target="blank"><h1>REGISTER NOW &gt;&gt;&gt; </h1></a></div>*/}
				{/*<div className="row" style={{marginTop: "30px"}}><h3>Stay Tuned!</h3></div>*/}
            </div>
        </>
        )
    }
}

export default Webinars
