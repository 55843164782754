import React, {Component} from 'react';
import competition from './assets/img/functions/function_competition.png';
import webinar from './assets/img/functions/function_webinar.png';
import trivia from './assets/img/functions/function_trivia.png';
import './FintechsticFunction.css';

export class FintechsticFunction extends Component{
    render() {
        return(
            <div className="container-sm" style={{marginTop: "30px", marginBottom: "30px"}}>
                <div className="row">
                    <div className="col-lg-4 function-list"><a href="https://ust.az1.qualtrics.com/jfe/form/SV_8pPgOkOGTpw1wBU" target="_blank"><img src={trivia}></img></a></div>
                    <div className="col-lg-4 function-list"><a href="https://www.instagram.com/p/CzA9Yu3RTnh/?igshid=MzRlODBiNWFlZA==" target="_blank"><img src={competition}></img></a></div>
					<div className="col-lg-4 function-list"><a href="https://fintech.hkust.edu.hk/home#news-events" target="_blank"><img src={webinar}></img></a></div>
						{/*<div className="col-lg-4 function-list"><a href="/#Competition"><img src={competition}></img></a></div>
						<div className="col-lg-4 function-list"><a href="/#Webinars"><img src={webinar}></img></a></div>*/}
                </div>
            </div>
        )
    }
}

export default FintechsticFunction
