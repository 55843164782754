import React, { Component } from 'react';
import "./About.css";
import Footer from './Footer';
//import aboutUsBanner from './assets/img/banner_aboutus.png';
import aboutus1 from './assets/img/aboutus/aboutus_1.png';
import aboutus2 from './assets/img/aboutus/aboutus_2.png';
import aboutus3 from './assets/img/aboutus/aboutus_3.png';
import aboutus4 from './assets/img/aboutus/aboutus_4.png';

class About extends Component {
    componentDidMount() {
        document.querySelector("body").scrollTo(0, 0)
    }
    render() {
        return (
            <>
                {/* <div className="container"> */}
                <div className="row" style={{marginBottom: "30px"}}>
                    <div className="col-3" style={{padding: 0}}><img src={aboutus1} /></div> 
                    <div className="col-3" style={{padding: 0}}><img src={aboutus2} /></div>
                    <div className="col-3" style={{padding: 0}}><img src={aboutus3} /></div>
					<div className="col-3" style={{padding: 0}}><img src={aboutus4} /></div>
                </div>
                {/* </div> */}


                <div id="About" className="container-sm" style={{ marginBottom: "100px" }}>

                    <div><h1>About Fintechstic</h1></div>
                    <div className="row">
                        <div className="col-md-12 about-text">
                            <p>
                                Fintechstic is an annual event organized by the Fintech and Green Finance Projects team of HKUST Business School. The event aims to connect university students with businesses and industry practitioners and to promote fintech to students and the public. Fintechstic was first conceptualized by a group of HKUST Fintech Student Ambassadors in 2020.
                                <br /><br />
                                The program typically runs between September and November in celebration of the Hong Kong FinTech Week and includes educational elements that are open to the public, as well as a student competition for university students. Each year, the event has a unique theme related to fintech.
                                <br /><br />
                                Over the past 3 years, Fintechstic has attracted about 3,000 students and members of the public to participate, with fabulous prizes presented to winners. In light of the growing importance of green finance in Hong Kong,  Fintechstic 2023 extends its reach to those who are interested in the sustainable development of Hong Kong in order to promote the awareness and knowledge of green finance to the wider Hong Kong community. 
                            </p>
                        </div>
                    </div>

                    <div><h1>About HKUST Business School</h1></div>
                    <div className="row">
                        <div className="col-md-12 about-text">
                            <p>
                                Founded in 1991, the HKUST Business School is young, innovative and committed to advancing global business knowledge. It is one of the first Asian business schools accredited by both AACSB and EQUIS. The School strives to contribute to the economic and social advancement of the region by developing future leaders who possess an innovative and entrepreneurial spirit as well as a strong sense of responsibility. We also take active steps to promote knowledge advancement in many significant business areas. For more information, please visit <a href="https://bm.hkust.edu.hk" target="_blank" className="link-primary">https://bm.hkust.edu.hk</a>.
                            </p>
                        </div>
                    </div>
                    <div style={{ marginTop: "30px" }}><h1>About Fintech and Green Finance Projects</h1></div>
                    <div className='row'>
                        <div id="about-frp-content" className="col-md-12 about-text">
                            <p>
                                The Fintech and Green Finance Projects of HKUST Business School aim to nurture the next generation of talent with interdisciplinary knowledge and mindset, and to promote and educate students and the public on fintech and green finance knowledge. Through collaboration with industry stakeholders, the team drives a wide range of talent development initiatives and student learning opportunities to enrich Hong Kong’s fintech and green finance talent pool.
                                <br /><br />
                                Assisting the scholars at HKUST to bring positive impacts on Hong Kong’s fintech and green finance development through influential and market-relevant research, the team works closely with stakeholders across the government, industry, and academia to facilitate intellectual exchange, produce practical Proof-of-Concepts and prototypes, and provide policy recommendations. The team’s mission is to deliver useful research outcomes that can further enhance Hong Kong’s fintech and green finance capabilities and help strengthen Hong Kong’s position as the world’s premier financial center. For details, please visit <a href="https://fintech.hkust.edu.hk/" target="_blank" className="link-primary">https://fintech.hkust.edu.hk/</a>.
                            </p>
                        </div>
                    </div>

                    <div style={{ marginTop: "30px" }}><h1>About TADS Awards</h1></div>
					<div className='row'>
						<div id="about-co-organiser" className="col about-text">
							<p>
								Inaugurated in Hong Kong in 2020 as a non-profit initiative, TADS Awards is the world’s first annual international awards for Tokenized Assets and Digital Securities (“TADS”) sector. It celebrates the TADS sector by recognizing and honouring significant contributions and distinguished achievements worldwide. Hosted annually, TADS Awards brings together individuals and businesses to share the energy that tokenization generates in the TADS sector. For more information, please visit <a href="https://tadsawards.org/" target="_blank" className="link-primary">https://tadsawards.org/</a>.
							</p>
						</div>
					</div>
                </div>
                <Footer />
            </>
        );
    }
}

export default About;