// ********** COMPONENT HOME PAGE **********

import React, { Component } from 'react';
import Keyvisual from './Keyvisual';
import Introduction from './Introduction';
import Competition from './Competition';
import Footer from "./Footer";
import FintechsticFunction from './FintechsticFunction';
import Webinars from './Webinars';
import About from './About';

class Home extends Component {
  
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() { 
    return (
        <div id="Home">
          <Keyvisual />
          {/* <hr/> */}
          <Introduction />
          <FintechsticFunction />
          <hr/>
          <Webinars />
          <hr/>
          <Competition />
          {/* <hr/>
          <QuizChallenge />
          <hr/>
          <InvestmentGame />
            <hr/>
            <Workshops />
          <hr/>
          <CaseCom /> */}
          {/* <hr/> */}
          {/* <Organizer /> */}
          <Footer />
        </div>
    );
  }
}

export default Home;
