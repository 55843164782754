import React, {Component} from 'react'
import kv from './assets/img/kv.png';

export class Keyvisual extends Component{
    render() {
        return(
            <div className="container">
                <img src={kv} />
            </div>
        )
    }
}

export default Keyvisual
