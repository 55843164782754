import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import './Competition.css';
import competitionBanner from './assets/img/banner_competition.png';
import championIcon from './assets/img/champion.png';
import runnerupIcon from './assets/img/runnerup.png';
import finalistsIcon from './assets/img/finalists.png';

export class Competition extends Component{
    render() {
        return(
            <>
                <a className="anchor" id="Competition"></a>
                <div id="Competition" className="competition" style={{"marginBottom": "50px" }}>
                    <div style={{ "minWidth": "100%", "marginTop": "30px", "marginBottom": "30px" }}>
                        <img src={competitionBanner} />
                    </div>
                    <div className="container-sm">
                        <span style={{lineHeight: "2rem"}}>The competition consists of a working period followed by a final day of presentation. Students from all universities in Hong Kong are welcome to form teams of 2 to 4 to tackle the challenge related to digital asset and green finance. This year’s Fintechstic Student Competition also serves as the Student Track of TADS Awards - the world’s first annual international awards for the Tokenized Assets and Digitized Securities (“TADS”) sectors. Students of the Champion Team of the Fintechstic 2023 Student Competition will be able to join the TADS Awards Ceremony and Gala Dinner with industry award recipients from around the world.</span>
                        <h2>THEME</h2>
                        <p>The New Paradigm of Digital Asset Under Regulatory Regime – Achieving Green SDGs in Hong Kong through Financial Innovations</p>
                        <h2>AWARDS</h2>
						<div className="row">
							<div className="col-lg-4 awards">
								<div className="iconframe"><img className="icon" src={championIcon} width="80" height="80"></img></div>
								<div>
									<b>Champion</b><br/>
									<ul>
										<li>HK$12,000</li>
										<li>Consultation session by HKUST Impact Ventures Program</li>
										<li>TADS Awards' winner's packages:</li>
										<ul>
											<li>Trophy</li>
											<li>NFT certificate</li>
											<li>Authorized usage of the TADS Awards icon</li>
											<li>Entitled to join the TADS Awards Ceremony & Gala Dinner</li>
										</ul>
									</ul>
								</div>
							</div>
                            <div className="col-lg-4 awards">
								<div className="iconframe"><img className="icon" src={runnerupIcon} width="80" height="80"></img></div>
								<div>
									<b>Runner-up</b><br/>
									<ul>
										<li>HK$8,000</li>
										<li>Consultation session by HKUST Impact Ventures Program</li>
									</ul>
								</div>
							</div>
                            <div className="col-lg-4 awards">
								<div className="iconframe"><img className="icon" src={finalistsIcon} width="80" height="80"></img></div>
								<div>
									<b>Finalists</b><br/>
									<ul>
										<li>Certificate of achievement</li>
										<li>Free tickets to the Hong Kong FinTech Week</li>
									</ul>
								</div>
							</div>
						</div>
                        {/*
						<div className="row">
                            <div className="col-lg-3 awards">
								<div className="iconframe">
									<img className="icon" src={championIcon} width="80" height="80"></img>
								</div>
							</div>
							<div className="col-lg-9 awards">
								One team of Champion:
								<ul>
									<li>HK$12,000 cash prize</li>
									<li>Certificate of achievement</li>
									<li>Consultation session by HKUST Impact Investing Club</li>
									<li>Free tickets to the Hong Kong FinTech Week</li>
									<li>TADS Awards' winner's packages</li>
									<ul>
										<li>Electronic certificate (non-transferable NFT on the blockchain)</li>
										<li>Trophy</li>
										<li>Authorized usage of the TADS Awards icon</li>
										<li>Entitled to join the TADS Awards Ceremony & Gala Dinner on 3 Nov 2023 (Fri)</li>
									</ul>
								</ul>
							</div>
                        </div>
						<div className="row">
						    <div className="col-lg-3 awards">
								<div className="iconframe">
									<img className="icon" src={runnerupIcon} width="80" height="80"></img>
								</div>
							</div>
							<div className="col-lg-9 awards">
								One team of Runner-up:
								<ul>
									<li>HK$8,000 cash prize</li>
									<li>Certificate of achievement</li>
									<li>Consultation session by HKUST Impact Investing Club</li>
									<li>Free tickets to the Hong Kong FinTech Week</li>
								</ul>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-3 awards">
								<div className="iconframe">
									<img className="icon" src={finalistsIcon} width="80" height="80"></img>
								</div>
							</div>
							<div className="col-lg-9 awards">
								Five teams of Finalists (including the champion team and runner-up):
								<ul>
									<li>Certificate of achievement</li>
									<li>Free tickets to the Hong Kong FinTech Week</li>
								</ul>
							</div>
						</div>
						*/}
                        <h2>KEY DATES</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="table-item">Application Deadline</td>
                                    <td>24 Sep (Sun, 23:59 HKT)</td>
                                </tr>
                                <tr>
                                    <td className="table-item">Topic Announcement</td>
                                    <td>Week of 25 Sep</td>
                                </tr>
                                <tr>
                                    <td className="table-item">Webinar 1</td>
                                    <td>27 Sep (Wed)</td>
                                </tr>
								<tr>
                                    <td className="table-item">Webinar 2</td>
                                    <td>11 Oct (Wed)</td>
                                </tr>
                                <tr>
                                    <td className="table-item">Proposal Submission Deadline</td>
                                    <td>15 Oct (Sun, 23:59 HKT)</td>
                                </tr>
                                <tr>
                                    <td className="table-item">Finalists Announcement</td>
                                    <td>25 Oct (Wed)</td>
                                </tr>
                                <tr>
                                    <td className="table-item">Grand Finale</td>
                                    <td>29 Oct (Sun)</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="row button-container" style={{marginTop: "30px", display: "none"}}>
                            <div className="col-md-4"><button type="button" className="btn btn-primary competition-btn">Information Leaflet</button></div>
                            <div className="col-md-4"><button type="button" className="btn btn-primary competition-btn" style={{width: "100%", height: "100%"}}>FAQ</button></div>
                            <div className="col-md-4"><button type="button" className="btn btn-primary competition-btn">Participation Agreement</button></div>
                        </div>
						{/*
						<h2>APPLICATION</h2>
						<p>All students from universities in Hong Kong, regardless of their fields of study, school, or academic level, are welcome to form teams of 2-4 members and participate in this competition.</p>
						<p>Interested students can submit their applications via the <a href="https://ust.az1.qualtrics.com/jfe/form/SV_7a0PVfPb5bdMRbo" target="_blank"  className="link-primary">application form</a> on or before 24 Sep (Sun, 23:59 HKT).</p>
						<p>For further information, please refer to the <a href="https://gohkust-my.sharepoint.com/:b:/g/personal/melodyto_ust_hk/EdhYfmHudaNNlH_XP9DYWYIBmtJwTPVLpR2-GZ_mfO8gDQ?e=pTabKl" target="_blank"  className="link-primary">student competition leaflet</a>.</p>
						<div className="row" style={{marginTop: "30px"}}><a href="https://ust.az1.qualtrics.com/jfe/form/SV_7a0PVfPb5bdMRbo" target="blank"><h3>Apply Now  &gt;&gt;&gt;</h3></a></div>
						*/}
                    </div>
                </div>
            </>
        )
    }
}

export default Competition
